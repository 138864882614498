<mat-card-header>
    <mat-card-title class="title-card">
        <div class="search-box"><mat-icon id="search">search</mat-icon>
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event)" placeholder="{{'audit.filter' | translate}}">
            </mat-form-field>
        </div>
    </mat-card-title>    
    <!-- <button (click)="exporter.exportTable('xlsx', {fileName:name_file})" class="download-button" mat-button>{{'buttons.download' | translate}} <i  class="fas fa-file-excel green-color" style="margin-left: 3px;"></i></button>  -->
</mat-card-header>
<mat-card-content>
    <div class="example-container">
        <table mat-table matTableExporter [dataSource]="dataSource" multiTemplateDataRows #exporter="matTableExporter" matSort (matSortChange)="sortData($event)" class="colortable">
            <ng-container *ngFor="let column of displayedColumns; let i = index" matColumnDef="{{column}}">
                <th mat-header-cell *matHeaderCellDef class="color text-center" mat-sort-header="{{column}}">
                    <span >
                        {{'table.'+column | translate}} 
                    </span>               
                </th>
                <td mat-cell *matCellDef="let element; let j=index" class=" {{ (column=='topicNameEvent' &&  element.topicNameEvent) ? element.topicNameEvent.replaceAll(' ', '_') : '' }}">
                    <span *ngIf="column=='orderNumberEvent'" class="link" (click)="pickedModal(element.topicNameEvent)">
                        {{element[column]}}
                    </span> 
                    <span *ngIf="column!='registrationDateEvent' && column!='orderNumberEvent' && column!='topicNameEvent'">
                        {{element[column]}}
                    </span>  
                    <span *ngIf="column=='registrationDateEvent'">                   
                        {{element[column] | date: 'HH:mm dd-MMM-yyy'}}
                    </span> 
                    <span *ngIf="column=='topicNameEvent'">
                        {{element[column]}}
                    </span> 
                </td>
            </ng-container> 
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div *ngIf="element.view_detail" class="example-element-detail"
                       [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram">
                      <table class="table-detail">
                        <tr>
                            <td class="field">Email:</td>
                            <td class="data-detail">{{element.email}}</td>
                            <td class="field">Telefono:</td>
                            <td class="data-detail">{{element.main_tlf}}</td>
                            <td class="field">Locker Serial:</td>
                            <td class="data-detail">{{element.serial_locker}}</td>
                        <tr>
                        <tr>
                            <td class="field">Locker:</td>
                            <td class="data-detail">{{element.locker_name}}</td>
                            <td class="field">Compartimiento:</td>
                            <td class="data-detail">{{element.door_number}}</td>
                            <td class="field">Tamaño Compartimiento:</td>
                            <td class="data-detail">{{element.compartment_name}}</td>
                        </tr>
                      </table>
                    </div>                    
                  </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
              class="table-row example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-card-content>