// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

// Modules
import { MaterialModule } from 'src/app/shared/libraries/angular-material/material.module';
// Translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';

// Components
import { ClientSign } from './client-dialog';
import { ClipVideoComponent } from './clip-video/clip-video.component';
import { ConfirmDelete } from './confirm-delete';
import { OrderCreateComponent } from './order-create/order-create.component';
import { SuccesDialogComponent } from './succes-dialog/succes-dialog.component';
import { UpdateEmail } from './update-email';
// import { ClientGrid } from './client-grid.component'; --Ni idea de para que sirve--
import { SharedModule } from 'src/app/shared/shared.module';
import { ComponentsAdminClientModule } from '../components-admin-client.module';
import { PhoneFormatDirective } from './order-create/phone-format.directive';

@NgModule({
	declarations: [
		ClientSign,
		ClipVideoComponent,
		ConfirmDelete,
		OrderCreateComponent,
		SuccesDialogComponent,
		UpdateEmail,
		PhoneFormatDirective
		// ClientGrid,s
	],
	exports: [
		ClientSign,
		ClipVideoComponent,
		ConfirmDelete,
		OrderCreateComponent,
		SuccesDialogComponent,
		UpdateEmail,
		// ClientGrid,
	],
	imports: [
		CommonModule,
		SharedModule,
		ComponentsAdminClientModule,
		MaterialModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
})
export class DialogsModule {}
