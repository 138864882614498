import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricalGrid2Component } from './components/historical-grid2/historical-grid2.component';
import { DeliveredReturnedPackagesComponent } from './components/delivered-returned-packages/delivered-returned-packages.component';
import { DeliveredPackagesSizeComponent } from './components/delivered-packages-size/delivered-packages-size.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { HeadquartersGrid2Component } from './components/headquarters-grid2/headquarters-grid2.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		HistoricalGrid2Component,
		DeliveredReturnedPackagesComponent,
		DeliveredPackagesSizeComponent,
		RatingsComponent,
		HeadquartersGrid2Component,
	],
	exports: [
		HistoricalGrid2Component,
		DeliveredReturnedPackagesComponent,
		DeliveredPackagesSizeComponent,
		RatingsComponent,
		HeadquartersGrid2Component,
	],
	imports: [CommonModule, NgApexchartsModule, SharedModule, TranslateModule],
})
export class ChartsGrid2Module {}
